.selectBoxes {
  display: flex;
}
.formSelect {
  grid-gap: 15px;
  gap: 15px;
  justify-content: flex-start;
  margin-bottom: 30px;
  width: 92% !important;
  margin: 0 auto 30px;
}
.viewBtnSec {
  display: flex;
  gap: 10px;
}
.formSelect .viewBtn {
  background-color: #32795f;
   font-size: 14px; 
   color: #fff;
   text-transform: capitalize;
   padding: 5px 8px;
}
.formSelect .viewBtn:hover {
  background-color: #32795f
}
.formSelect .viewBtn.Mui-disabled {
  border: 1px solid #999999;
  background-color: #6c757d;
  color: #ffffff;
}
.MuiDataGrid-columnHeaderTitleContainer {
  overflow: auto !important;
  min-width: max-content !important;
  white-space: normal !important;
  padding: 0 !important;
}
.period-grid {
position: relative;
}
.period-input {
border: 1px solid hsl(0, 0%, 80%);
border-radius: 4px;
min-height: 38px;
width: 100%;
padding: 2px 8px;
}
.period-input:focus:not([data-focusvisible-polyfill]),
.period-input:focus {
border: 2px solid #2684FF;
}
.rdrCalendarWrapper {
position: absolute;
top: 40px;
left: 0;
z-index: 9;
box-shadow: 0px 3px 6px #ccc;
}
.rdrCalendarMainWrapper {
background-color: #fff;
position: absolute;
top: 40px;
left: 0;
z-index: 9;
box-shadow: 0px 3px 6px #ccc;
}
.date-range-wrapper {
position: relative;
top: 0;
box-shadow: none;
}
.rdrCalendarBtns {
display: flex;
align-items: center;
justify-content: flex-end;
gap: 5px;
padding: 0 15px 10px;
}
.closeIcon{
  display: flex;
  justify-content: end;
  margin: 15px 15px 5px auto;
  border:1px solid #ccc;
  border-radius: 50%;
  box-shadow: 0 0 12px 2px #ccc;
  width: fit-content;
  padding: 10px;
  }
  .closeIcon img:hover {
    cursor: pointer;
}
/* CSS for invoices download*/

.invoiceDialog .MuiDialog-paper {
  max-width: 2000px;
  width: 55%;
  min-height: 632px;
  max-height: 632px;
}

.invoiceBack {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0px 15px 15px;
  border: 1px solid #ccc;
  border-radius: 50%;
  box-shadow: 0 0 12px 2px #ccc;
  width: 40px;
  height: 40px;
  padding: 10px;
}

.headingContSec {
  width: 100%;
  display: flex;
  align-items: center;
}

.headingContSec .invoiceBack {
  margin: 15px 15px 15px 0;
}

.upiInputRaise {
  padding: 20px 0;
  width: 50%;
  margin: 20px auto;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.popup-custom {
  width: 95%;
  margin: 20px auto;
}

.invoiceSection {
width: 100%;
background-color: #fff;
font-family: sofiaPro;
}

.successPageSection{
  width: 100%;
  background-color: #fff;
  font-family: sofiaPro;
  margin-top: 100px;
}

.invoiceBg {
background-color: #e8f5ee;
padding: 20px;
min-height: 85px;
margin-bottom: 20px;
}

.viewInvoices .pdf-canvas canvas {
  width: 100%;
}
.viewInvoices .pg-viewer-wrapper {
  overflow-y: hidden;
}
@media print {
  .invoiceBg {
    background-color: #e8f5ee !important;
  }
}
@media print {
  .print-hidden {
    display: none !important;
  }
}
.invoiceTopArea {
display: flex;
justify-content: space-between;
}

.invoiceLogoArea {
display: flex;
align-items: center;
gap: 10px;
}

.invoiceLogoArea img {
width: 135px;
}

.invoiceLogoArea h4 {
font-size: 18px;
font-weight: 600;
}

.invoicedatedArea {
display: flex;
align-items: flex-start;
text-align: right;
gap: 25px;
}

.invoicedatedArea h6:last-child,
.invoiceHeaderInfoArea h6:last-child  {
color: #000;
font-weight: 700;
}

.invoiceDateDetails {
  padding: 0 25px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.invoiceDateDetails > div {
  margin-bottom: 10px;
}

.invoiceHeaderBottomArea {
width: 90%;
margin: 0 auto;
position: relative;
}

.invoiceHeaderInfoArea {
display: flex;
align-items: flex-end;
flex-wrap: wrap;
justify-content: space-between;
gap: 20px;
width: 95%;
margin: 0 auto 20px;
}

.invoiceHeaderInfoArea h6:last-child {
margin-top: 5px;
}

.invoiceHeaderEnd {
margin-bottom: 15px;
position: absolute;
width: 100%;
padding-bottom: 30px;
border-bottom: 3px solid #ccc;
}

.invoiceHeaderWhiteArea {
border: 1px solid #ccc;
border-radius: 5px;
background-color: #fff;
min-height: 100px;
box-shadow: 0 1px 13px 1px #ccc;
padding: 20px 0;
}

.invoiceHeaderInnerArea {
padding: 0 20px;
}

.invoiceHeaderServiceInfo {
width: 100%;
}

.invoiceHeaderServiceInfo > div {
width: 30%;
}

.invoiceBlankSpace {
min-height: 75px;
padding: 0 10px;
}

.invoiceTermsBox {
background-color: #efe800;
padding: 10px 20px;
border-radius: 5px;
}

.invoiceTermsBox h6 {
width: 35%;
line-height: 1.35;
}

.invoiceInTable {
width: 90%;
margin: 0 auto 0;
position: relative;
}

.invoiceInTable table {
background-color: #fff;
}

.invoiceInTable th,
.invoiceInTable td {
font-size: 12px;
font-family: sofiaPro;
}

.invoiceInTable tbody td {
font-weight: 700;
}

.noAttachment {
font-family: sofiaPro;
text-align: center;
text-align: center;
height: 45px;
border-bottom: 1px solid #ccc;
margin-bottom: 15px;
}

.modeOption {
color: #000;
padding-bottom: 15px;
font-weight: bold !important;
font-family: sofiaPro;
}

.invoicefooter h6 {
color: #000;
font-family: sofiaPro;
}

.invoicefooter h6:first-child {
padding-bottom: 2px;
font-weight: bold !important;
}

.invoicefooter .MuiTypography-subtitle1 {
margin-top: 10px;
color: #8d97a1;
font-weight: bold;
text-transform: capitalize;
font-family: sofiaPro;
}

.invoiceSignArea {
border: 1px solid #ccc;
height: 50px;
margin: 10px 0;
}

.invoiceTableBorder {
padding: 16px 16px 6px !important;
border-left: 1px solid rgba(224, 224, 224, 1)
}

.invoiceSignature {
height: 50px;
width: auto;
object-fit: contain;
object-position: left;
}
.innerBtn{
display: flex;
gap: 10px;
}


/*trial code */
.custom-button {
background-color: #32795f;
color: #fff;
padding: 10px 20px; 
border: none;
border-radius: 5px;
cursor: pointer;

}

.custom-button:hover {
background-color:  #32795f;
}

.closeDivision {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 0 15px;
  gap: 15px;
}

@media print {
.dashBoard_sidebar,
.dashBoard_content {
  display: none;
}
.invoiceDialog .MuiDialog-paper {
  width: 100%;
  margin: 0;
}
.invoiceSection {
  width: 100%;
}
.invoiceHeaderBottomArea,
.invoiceInTable {
  width: 100%;
}
.invoiceInTable table tbody tr:last-child td {
  border-bottom: none;
}
.invoiceInTable table tbody tr:nth-last-child(4) td {
  padding-top: 60px;
}
}